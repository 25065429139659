// External Dependencies
import React from 'react';

// Internal Dependencies
import HeaderGeneric from '../components/HeaderGeneric';
import Layout from '../components/layout';
import Meta from '../components/Meta';
import FeaturesList from '../components/FeaturesList';

// Component definition
const Features = () => {
  return (
    <Layout>
      <Meta title="Features" />

      <HeaderGeneric
        subtitle="The list of features in Presto Assistant continues to grow!"
        title="Presto Assistant Features"
      />

      <div id="main">
        <section id="content" className="main">
          <FeaturesList />
        </section>
      </div>

    </Layout>
  );
};

export default Features;
