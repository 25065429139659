// External Dependencies
import React from 'react';
import Chip from '@mui/material/Chip';

// Component definition
const FeaturesList = () => (
  <article>
    <section>
      <h3>Ease of use</h3>

      <ul>
        <li>
          Presto&apos;s modern interface makes it easy to understand how to use.
        </li>
        <li>
          Website works on desktop and mobile browsers, anywhere you need it!
        </li>
      </ul>
    </section>

    <section>
      <h3>Physical inventory item management</h3>

      <ul>
        <li>
          District level and organization level
        </li>
        <li>
          Inventory (instruments, electronics, etc.)
        </li>
        <li>
          Uniforms
        </li>
        <li>
          Music Library (view library items across your district)
        </li>
        <li>
          Checkout inventory and uniforms to members
        </li>
        <li>
          Assign a district-wide inventory audit to account for all inventory items
        </li>
      </ul>
    </section>

    <section>
      <h3>District Administrators</h3>

      <ul>
        <li>
          Every subscribed organization in Presto belongs to a district.
        </li>
        <li>
          District administrators have their own Presto interface where they
          can manage district-wide data, such as inventory,
          uniforms, library, organizations, Presto licenses, etc.
        </li>
        <li>
          District Administrators can add District Assistants
          to help manage the district&apos;s Presto data.
          <ul>
            <li>
              A District Assistant has the same top-level permissions as a District Administrator.
            </li>
          </ul>
        </li>
        <li>
          Manage permissions for all directors in the district.
        </li>
        <li>
          Add district-wide forms that can be assigned to members in the
          types of organizations in your district that you choose.
        </li>
        <li>
          <Chip
            label="NEW"
            size="small"
            sx={{
              backgroundColor: '#450291',
              color: 'white',
              marginRight: 1,
              padding: '0 3px',
            }}
            variant="filled"
          />
          Create an inventory checkout form for all organizations in the district.
        </li>
        <li>
          <Chip
            label="NEW"
            size="small"
            sx={{
              backgroundColor: '#450291',
              color: 'white',
              marginRight: 1,
              padding: '0 3px',
            }}
            variant="filled"
          />
          View all email communication sent by members in the district.
        </li>
      </ul>
    </section>

    <section>
      <h3>Manage all members</h3>

      <ul>
        <li>
          Students and parents have their own unique accounts.
        </li>
        <li>
          Students, parents, or directors in more than one organization
          have a single login, and can easily switch between organizations
          while signed in.
        </li>
        <li>
          Members can reset passwords on their own
          (directors don&apos;t have to reset member passwords themselves!).
          Secure password resets are only good for a single
          use, which helps keep Presto user login data safe.
        </li>
        <li>
          Create groups to quickly reference in other parts of the system.
          <ul>
            <li>
              Most directors add each school period and groups like booster officers.
            </li>
          </ul>
        </li>
        <li>
          Directors and District Administrators have a &quot;switch to user&quot;
          feature. This allows you to jump into a read-only view of
          a student or parent to get a glimpse of their view.
        </li>
        <li>
          Directors and District Administrators can assign permissions for
          adult Presto members. Parents helping manage booster finances
          or organize uniforms can have specific permissions to see only those parts of the system.
        </li>
      </ul>
    </section>

    <section>
      <h3>Finances</h3>

      <ul>
        <li>
          This video walkthrough (<a href="https://support.presto-assistant.com/videos/finances-walkthrough/">link</a>) of our finances feature is a good
          place to see an overview for all features.
        </li>
        <li>
          Generate balance sheets to have a report of what each member owes
          (even members from previous years with an outstanding balance).
        </li>
        <li>
          Email statements to members with the click of a button,
          choosing those with a balance or all members.
        </li>
        <li>
          Statements are easily human readable — you typically
          will not see confusing negative numbers on statements.
          <ul>
            <li>
              <Chip
                label="NEW"
                size="small"
                sx={{
                  backgroundColor: '#450291',
                  color: 'white',
                  marginRight: 1,
                  padding: '0 3px',
                }}
                variant="filled"
              />
              Automatically email statements to members with a balance or all members:
              monthly, twice a month, or weekly.
            </li>
          </ul>
        </li>
        <li>
          Finances Overview dashboard shows the overall financial health of your organization.
        </li>
        <li>
          Payments can be made on fees, either partially, fully, or they can be overpaid.
        </li>
        <li>
          Overpayments auto-generate a credit that can later be applied to future payments.
        </li>
        <li>
          Credits can also be manually entered individually or in bulk.
          <ul>
            <li>
              A good use case for this is when coming from another
              software application, and students have a positive balance.
            </li>
          </ul>
        </li>
        <li>
          &quot;One-off Fees&quot; can also be manually entered individually
          or in bulk in the event students have a negative outstanding
          balance to start.
          <ul>
            <li>
              Most people will import all assigned student fees
              from another platform using &quot;One-off Fees&quot;.
            </li>
          </ul>
        </li>
        <li>
          When a payment is created (either entered by the director or made online by a parent),
          a payment receipt is emailed to the student and parent automatically.
        </li>
        <li>
          Credits can be transferred between family members, either in part or in full.
        </li>
        <li>
          Waive fees assigned to students
        </li>
        <li>
          Refund payments (even online payments)
        </li>
        <li>
          Fundraiser Credits will reduce the amount of money owed
          on fees for the student assigned to that fee.
        </li>
      </ul>
    </section>

    <section>
      <h3>Online Payments</h3>

      <ul>
        <li>
          Set up online payments using either{' '}
          <a href="https://www.stripe.com" target="_blank" rel="noreferrer">Stripe</a>{' '}
          or{' '}
          <a href="https://www.vancopayments.com/" target="_blank" rel="noreferrer">Vanco RevTrak</a>.
        </li>

        <li>
          Parents will be able to pay online using a credit card for their student&apos;s fees,
          all directly inside of Presto.
        </li>
      </ul>
    </section>

    <section>
      <h3>Email Communication</h3>

      <ul>
        <li>
          Select members to email, easily filtering by group, grade, instrument, etc.
          <ul>
            <li>
              Adult relatives of students are always automatically emailed, too.
            </li>
          </ul>
        </li>
        <li>
          You will not get logged out while composing emails.
        </li>
        <li>
          As you are typing, Presto will automatically save your email as a draft.
          <ul>
            <li>
              No more losing the email you are typing if you get distracted!
            </li>
          </ul>
        </li>
        <li>
          Schedule emails to be sent at a later date and time.
        </li>
        <li>
          Optionally carbon copy (CC) all directors in the organization.
          You can also have individual “write-ins” for CC.
          <ul>
            <li>
              This is helpful in the event the principal wants to be
              CC&apos;d — no Presto account needed!
            </li>
          </ul>
        </li>
        <li>
          A manageable &quot;email suppression list&quot; gives you
          insight into which members are not receiving emails and why.
          <ul>
            <li>
              Typically this is due to a typo in an email address or a full inbox.
            </li>
          </ul>
        </li>
        <li>
          Multiple attachments can be included on emails, up to 10 MB.
        </li>
        <li>
          View previously sent emails and download attachments.
        </li>
        <li>
          SMS text communication — not available at this time, but will be in the future.
        </li>
      </ul>
    </section>

    <section>
      <h3>Forms</h3>

      <ul>
        <li>
          Use Presto&apos;s built-in form builder to create digital forms
          for students or parents to complete.
        </li>
        <li>
          District forms allow a District Administrator to create a form
          and assign to all director, students, and/or parents in organizations across the district.
        </li>
        <li>
          Choose from a variety of field types, including:
          <ul>
            <li>
              Signature, Initials, File download, Heading, Paragraph,
              Short response, Long response, Multiple choice, Checkbox, Date,
              Numeric
            </li>
          </ul>
        </li>
        <li>
          View all form responses in Presto where you can filter, sort, and search results.
        </li>
        <li>
          <strong>Coming soon:</strong>{' '}
          <ul>
            <li>
              Custom, hand-drawn digital signatures.
            </li>
          </ul>
        </li>
      </ul>

    </section>

    <section>
      <h3>Files</h3>

      <ul>
        <li>
          Upload any file type, and we&apos;ll store it for you.
        </li>
        <li>
          Files can have a start and/or end date,
          meaning a student can only access those files within that date range.
        </li>
        <li>
          Files can be:
          <ul>
            <li>
              Assigned to students by group or instrument,
              such as assigning a file to all flutes in wind ensemble
            </li>
            <li>
              Globally available to all members in your organization
            </li>
            <li>
              Or private, only available to directors
            </li>
          </ul>
        </li>
      </ul>
    </section>

    <section>
      <h3>Calendar</h3>

      <ul>
        <li>
          Sync with Google Calendar
        </li>
        <li>
          Presto conveniently reads calendar events from Google Calendar.
          Any updates that are made to Google Calendar will be visible in Presto.
        </li>
        <li>
          Google Calendar allows a user to have multiple calendars in their account.
          They might have a work calendar, personal calendar, birthdays, holidays, etc.
          The director who syncs Google Calendar initially can select which individual calendars
          will be displayed to the organization. None are selected by default.
        </li>
      </ul>
    </section>

    <section>
      <h3>End-of-Year Process</h3>

      <ul>
        <li>
          When it&apos;s time to transition to the next school year, we give you a helpful reminder.
        </li>
        <li>
          Every organization can have a &quot;successor organization&quot;.
          For example, a middle school band organization will list
          a high school band organization as their successor organization.
        </li>
        <li>
          Members can be individually assigned an override for the successor
          organization, in the event a student is going to
          attend a different school than most of their peers.
        </li>
        <li>
          Members are automatically bumped up a grade when completing the end-of-year process.
          <ul>
            <li>
              If they are an 8th grader, and your &quot;graduating grade&quot; is
              8, then they are placed in the successor organization for next
              year as a 9th grader when completing the end-of-year process.
            </li>
            <li>
              Connected parents are automatically moved to the successor organization, too.
            </li>
          </ul>
        </li>
        <li>
          The end-of-year process can be completed in any order.
          The middle school does not have to wait for the high
          school to complete the process first, or vice versa.
        </li>
      </ul>
    </section>

    <section>
      <h3>Dynamic Fields</h3>

      <ul>
        <li>
          Many items in Presto have a feature we call dynamic fields.
          An example when this might be beneficial is a theater
          program needing &quot;time period&quot; as a field on uniforms.
          This is a field that would only be applicable to theater.
        </li>
        <li>
          Any resource that has dynamic fields available can have up to 50 dynamic fields.
        </li>
        <li>
          Dynamic field types include: text, boolean (true/false), date, and number
        </li>
        <li>
          When a dynamic field is added, it is available when
          importing data, viewing a table of data, or viewing an individual record of data.
        </li>
      </ul>
    </section>

    <section>
      <h3>Permissions</h3>

      <ul>
        <li>
          District administrators can manage permissions for directors in their organizations.
          They can set the ability to read, write, edit, or delete a variety of items in Presto.
        </li>
        <li>
          Administrators can give directors the ability to edit other users&apos; permissions.
        </li>
        <li>
          Directors (if permitted) can turn on permissions for individual parents.
          They can grant any permission to parents, as long
          as it is a permission they have been granted themselves.
        </li>
      </ul>
    </section>

    <section>
      <h3>Change History</h3>

      <ul>
        <li>
          Presto is an application that prioritizes transparency and accountability.
          Critical information, such as physical inventory items,
          financial information, and member information have a change history.
          Anytime a change is made, whether creating, editing, or
          deleting, the change is recorded (who, when, and what changed).
        </li>
        <li>
          When these critical items are deleted,
          Presto does a &quot;soft delete&quot;. This means
          the information still exists, but you won&apos;t see it in the application.
        </li>
        <li>
          Administrators are able to see inventory items that have been deleted.
        </li>
      </ul>
    </section>

    <section>
      <h3>Support</h3>

      <ul>
        <li>
          Reach out with questions and expect a timely response from our team.
        </li>
        <li>
          Support site articles (<a href="https://support.presto-assistant.com/">link</a>) include
          helpful screenshot walkthroughs for using Presto&apos;s features.
        </li>
        <li>
          Video walkthroughs (<a href="https://support.presto-assistant.com/videos/">link</a>) of several Presto Assistant features will help you get started quickly.
        </li>
        <li>
          Directors and District Administrators have an
          in-app feedback feature to ask questions or give feedback.
        </li>
        <li>
          Robust error monitoring.
          <ul>
            <li>
              If a user gets stuck using Presto due to any kind of unforeseen issue,
              the Presto team will be notified and can investigate.
            </li>
          </ul>
        </li>
        <li>
          Our releases page (<a href="https://www.presto-assistant.com/releases/">link</a>) shows regular updates of new
          features and bug fixes. Although updates are released
          multiple times per week, release notes are typically on a monthly cadence.
        </li>
      </ul>
    </section>

  </article>
);

export default FeaturesList;
